<template>
  <v-dialog
      v-model="show"
      width="800"
    >
      <v-card>
        <v-toolbar>
        <v-toolbar-title>Error Found</v-toolbar-title>
      </v-toolbar>
        <br/>
        <v-card-text v-if="error_code == 'NO_FUTURE_PERMISSION'">
          <div>This API key and secret key can not be used to connect to this trading bot.</div>
          <div>
            <v-img
            :src="require('@/assets/binanceError/NO_FUTURE_PERMISSION.jpg')"
            contain
          />
          </div>
          <br/>
          <div>
            Please specify the authority of access according to the instruction picture only.
          </div>
        </v-card-text>

        <v-card-text v-else-if="error_code == 'NO_SPOT_PERMISSION' ">
          <div>This API key and secret key can not be used to connect to this trading bot.</div>
          <div>
            <v-img
            :src="require('@/assets/binanceError/NO_SPOT_PERMISSION.jpg')"
            contain
          />
          </div>
          <br/>
          <div>
            Please specify the authority of access according to the instruction picture only.
          </div>
        </v-card-text>

        <v-card-text v-else-if="error_code == 'NO_ONLY_FUTURE_PERMISSION' ">
          <div>This API key and secret key can not be used to connect to this trading bot.</div>
          <div>
            <v-img
            :src="require('@/assets/binanceError/NO_ONLY_FUTURE_PERMISSION.jpg')"
            contain
          />
          </div>
          <br/>
          <div>
            Please specify the authority of access according to the instruction picture only.
          </div>
        </v-card-text>

        <v-card-text v-else-if="error_code == 'CANT_CHANGE_POSITION_MODE' || error_code == 'CANT_CHANGE_MARGIN_TYPE' || error_code == 'CANT_CHANGE_LEVERAGE'">
          <div>
            Please close your pending Position / Order before setting up the API Key.
          </div>
        </v-card-text>

        <v-card-text v-else-if="error_code == 'BROKEN_KEY' ">
          <div>
            The API Key encountered an issue. Please generate a new key and provide the authority of access.
          </div>
        </v-card-text>

        <v-card-text v-else-if="error_code == 'Bot is expired.' ">
          <div>
            Bot is expired.
          </div>
        </v-card-text>

        <v-card-text v-else-if="error_code == 'INCORRECT_KEY' ">
          <div>This API key and secret key can not be used to connect to this trading bot.</div>
          <div>
            <v-img
            :src="require('@/assets/binanceError/INCORRECT_KEY.jpg')"
            contain
          />
          </div>
          <br/>
          <div>
            Please create API according to the process specified by Binance.
          </div>
          <br/>
          <div>
            <v-img
            :src="require('@/assets/binanceError/INCORRECT_KEY_1.jpg')"
            contain
          />
          </div>
          <br/>
          <div>
            and specify the authority of access as recommended in the instruction picture.
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="show = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "errorDialog",
  props: ["dialogStatus", "error_code"],
  data: () => {
    return {
    };
  },
  mounted(){
  },
  methods: {
  },
  computed: {
    show: {
      get() {
        return this.dialogStatus;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>

<style scoped>
</style>