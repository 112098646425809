export function getLeverageList() {
  return [
    { text: "1x", value: 1 },
    { text: "2x", value: 2 },
    { text: "3x", value: 3 },
    { text: "4x", value: 4 },
    { text: "5x", value: 5 },
    { text: "6x", value: 6 },
    { text: "7x", value: 7 },
    { text: "8x", value: 8 },
    { text: "9x", value: 9 },
    { text: "10x", value: 10 },
    { text: "11x", value: 11 },
    { text: "12x", value: 12 },
    { text: "13x", value: 13 },
    { text: "14x", value: 14 },
    { text: "15x", value: 15 },
    { text: "16x", value: 16 },
    { text: "17x", value: 17 },
    { text: "18x", value: 18 },
    { text: "19x", value: 19 },
    { text: "20x", value: 20, recommend: true },
    { text: "10x", value: 10 },
    { text: "21x", value: 21 },
    { text: "22x", value: 22 },
    { text: "23x", value: 23 },
    { text: "24x", value: 24 },
    { text: "25x", value: 25 },
    { text: "26x", value: 26 },
    { text: "27x", value: 27 },
    { text: "28x", value: 28 },
    { text: "29x", value: 29 },
    { text: "30x", value: 30 },
  ];
}
